html {
  height: -webkit-fill-available;
}

.wrapper {
  min-height: -webkit-fill-available;
  display: flex;
  flex-direction: column;
}

/*
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
*/

header {
  background-color: black;
}
main {
  flex: 1 0 auto;
}
footer {
  background-color: beige;
  flex-shrink: 0;
}

@supports (-webkit-touch-callout: none) {
  /* CSS specific to iOS devices */
  .focuscourse {
    display: none;
  }
}
